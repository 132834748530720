import "./../scss/index.scss";
import "./../scss/about.scss";

const burger = document.getElementById("burger");
const header = document.querySelector(".header");
const nav = document.querySelector("nav");
const links = document.querySelectorAll(".link");

burger.addEventListener("click", (event) => {
  // MAXIMISE HEADER TO COVER ENTIRE SCREEN
  header.classList.toggle("header-active");
  nav.classList.toggle("active");
});

links.forEach((link) =>
  link.addEventListener("click", () => {
    nav.classList.remove("active");
  })
);


// OPEN DIAGLOG BOX
const floatingBtn = document.querySelector(".floating-btn");
const closeBtn = document.querySelector(".dialog-close-btn-container");
const dialogBox = document.querySelector(".dialog");
const html = document.querySelector("html");

window.addEventListener("load", () => {
  if (document.cookie.includes("popupShown")) {
    return;
  } else {
    // ADD COOKIE TO PREVENT POPUP FROM SHOWING AGAIN
    let date = new Date(Date.now() + 2592e5);
    date = date.toUTCString();
    document.cookie = "popupShown=true; expires=" + date;
    setTimeout(() => {
      dialogBox.showModal();
    }, 5000);
  }
});

floatingBtn.addEventListener("click", () => {
  dialogBox.showModal();
  // Disable scrolling when dialog box is open
  html.style.overflow = "hidden";
});

// CLOSE DIAGLOG BOX
closeBtn.addEventListener("click", () => {
  // Clear all input fields
  const inputFields = document.querySelectorAll("input");
  const textArea = document.querySelector("textarea");
  inputFields.forEach((input) => {
    input.value = "";
    textArea.value = "";
  });
  dialogBox.close();
  html.style.overflow = "auto";
});


// NAVIGATE TO HOME PAGE ON LOGO CLICK
const logo = document.querySelector(".logo-container");
logo.addEventListener("click", () => {
  window.location.href = "index.html";
});

// DROP SHADOW UNDER NAVIGATION AFTER SCROLLING
window.addEventListener("scroll", () => {
  const scrolled = window.pageYOffset;
  const value = 100;
  const navbar = document.querySelector(".header");

  if (scrolled > value) {
    navbar.classList.add("nav-drop-shadow");
  } else {
    navbar.classList.remove("nav-drop-shadow");
  }
});

// FLOATING IMAGE ANIMATION
let parallaxContainer = document.querySelector(".free-floating-image-container");
const image1 = document.querySelector(".parallaxImage1");
const image2 = document.querySelector(".parallaxImage2");
const image3 = document.querySelector(".parallaxImage3");
const image4 = document.querySelector(".parallaxImage4");
const image5 = document.querySelector(".parallaxImage5");
const image6 = document.querySelector(".parallaxImage6");
const image7 = document.querySelector(".parallaxImage7");
const image8 = document.querySelector(".parallaxImage8");
const navbar = document.querySelector(".header")

if (parallaxContainer !== null && window.getComputedStyle(parallaxContainer).display !== 'none') {
  window.addEventListener("scroll", () => {
    const scrolled = window.pageYOffset;
    const val = scrolled * 0.2;

    image1.style.transform = `translateY(-${val * 0.5}%)`;
    image2.style.transform = `translateY(-${val * 0.2}%)`;
    image3.style.transform = `translateY(-${val * 0.2}%)`;
    image7.style.transform = `translateY(-${val * 0.3}%)`;
  });

  window.addEventListener("scroll", () => {
    const scrolled = window.pageYOffset;
    const val = scrolled * 0.4;

    image4.style.transform = `translateY(-${val * 0.08}%)`;
    image5.style.transform = `translateY(-${val * 0.2}%)`;
    image6.style.transform = `translateY(-${val * 0.3}%)`;
    image8.style.transform = `translateY(-${val * 0.3}%)`;
  });
}
// FAQs ACCORDION

const accordianButton = document.getElementsByClassName("accordian-btn");


for (let i = 0; i < accordianButton.length; i++) {
  accordianButton[i].addEventListener("click", () => {
    console.log("clicked");
    accordianButton[i].classList.toggle("active");
    const answer = accordianButton[i].nextElementSibling;
    if (answer.style.display === "block") {
      answer.style.display = "none";
    } else {
      answer.style.display = "block";
    }
  });
}